/* CSS for All Page */

body {
  margin: 0;
  width: 1200px;
  height: 100vh;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #EEF5FC.23%, #eef5fc 100%) !important;
  background: linear-gradient(180deg, #EEF5FC.23%, #eef5fc 100%) !important;
}

.logout-button-area {
  position: absolute;
  margin-left: 1180px;
  margin-top: 13px;
}

.logout-button {
  position: absolute;
  width: 70px;
  height: 30px;
}

.heade-area {
  position: absolute;
  display: flex;
  border: 1px solid #e0e0e0 !important;
  background-color: white !important;
  width: 1260px;
  height: 50px;
  overflow: hidden;
}

.lexx-logo {
  position: absolute;
  height: 48px;
  width: 84px;
  margin-top: 2px;
  margin-left: 20px;
}

.text-mid {
  width: 530px;
  height: 19px;
  position: absolute;
  font-family: "Avinir";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  margin-left: 400px;
  color: #000000;
}

.date {
  position: absolute;
  margin-left: 1000px;
  margin-top: 20px;
  color: #000000;
  font-family: "Avinir";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  width: 530px;
  height: 19px;
}

.fromfield-area {
  position: absolute;
  background-color: white;
  margin-left: 1%;
  margin-top: 90px;
  width: 1000px;
  height: 2000px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.fromfield-area-2 {
  position: absolute;
  background-color: white;
  margin-left: 1%;
  margin-top: 90px;
  width: 1000px;
  height: 2000px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.form-text-group {
  width: 43px;
  height: 25px;
  margin-left: 25px;
  margin-top: 50px;
  position: absolute;
}

.input-group {
  position: absolute;
  width: 280px;
  height: 48px;
  margin-left: 20px;
  margin-top: 80px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
}
.input-group-required{
  width: 43px;
  height: 25px;
  margin-left: 25px;
  margin-top: 50px;
  position: absolute;
}
.input-group-required span::after{
  content: "*";
  color: red;
}

.form-text-configuration {
 position: absolute;
  margin-top: 50px;
  margin-left: 355px;
}
.form-text-configuration-required{
  position: absolute;
  margin-top: 50px;
  margin-left: 355px;
}
.form-text-configuration-required span::after {
  content: "*";
  color: red;
}

.input-configuration {
  position: absolute;
  width: 280px;
  height: 48px;
  margin-left: 350px;
  margin-top: 80px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
  /* position: absolute;
  width: 280px;
  height: 48px;
  margin-left: 20px;
  margin-top: 450px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px; */
}

/* .form-text-configuration::after{
  content: " *";
  color: red;
  font-size:20px;
  margin-top: 100px;
  position: absolute;
} */
.form-text-date {
  width: 43px;
  height: 25px;
  margin-left: 700px;
  margin-top: 50px;
  position: absolute;
}

.form-text-date span::after {
  content: "*";
  color: red;
}

.input-date-area {
  position: absolute;
  width: 280px;
  height: 48px;
}

/* .form-text-configuration::after{
  content: " *";
  color: red;
  font-size:20px;
  margin-bottom: 200px;
  margin-left: 4px;
  position: absolute;
} */
.datepicker {
  position: absolute;
  width: 280px;
  height: 48px;
  margin-left: 700px;
  margin-top: 68px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
}

.form-text-instructions {
  position: absolute;
  margin-top: 210px;
  margin-left: 55px;
}

.form-text-ei {
 
  margin-left: 25px;
  margin-top: 420px;
  position: absolute;
}

.form-text-ei span::after {
  content: "*";
  color: red;
}

.form-text-revision {
  width: 43px;
  height: 25px;
  margin-left: 355px;
  margin-top: 420px;
  position: absolute;
}

.form-text-revision span::after {
  content: "*";
  color: red;
}

.text-instructions {
  position: absolute;
  width: 300px;
  height: 96px;
  margin-left: 40px;
  background: #ffffff;
  border: 1px solid #0971ce;
  border-radius: 8px;
  margin-top: 240px;
}

.text-ei {
  /* position: absolute;
  width: 280px;
  height: 48px;
  margin-left: 350px;
  margin-top: 80px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px; */
   position: absolute;
  width: 280px;
  height: 48px;
  margin-left: 20px;
  margin-top: 450px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
}

.text-revision {
  position: absolute;
  width: 280px;
  height: 48px;
  margin-left: 350px;
  margin-top: 450px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
}

.form-text-customer {
  position: absolute;
  margin-top: 210px;
  margin-left: 25px;
}

.form-text-customer span::after {
  content: "*";
  color: red;
}

.form-text-account {
  position: absolute;
  margin-top: 210px;
  margin-left: 355px;
}

.form-text-account span::after {
  content: "*";
  color: red;
}

.form-text-compilance {
  position: absolute;
  margin-top: 210px;
  margin-left: 700px;
}

.input-customer {
  position: absolute;
  width: 280px;
  height: 48px;
  margin-left: 20px;
  margin-top: 240px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
}

.input-account {
  position: absolute;
  width: 280px;
  height: 48px;
  margin-left: 350px;
  margin-top: 240px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
}

.input-compliance {
  position: absolute;
  width: 280px;
  height: 48px;
  margin-top: 240px;
  margin-left: 700px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
}

.form-text-documents {
  position: absolute;
  margin-top: 620px;
  margin-left: 25px;
}
#inputadddocument {
  position: absolute;
  margin-top: 640px;
  margin-left: 55px;
  display: flex;
  flex-direction: column;
}
.input-documents {
  position: absolute;
  margin-top: 650px;
  width: 280px !important;
  height: 48px;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
  margin-left: 20px;
}

.add-button {
  gap: 5px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 705px;
  left: 350px;
  background: #0971ce;
  color: white;
  border-radius: 5%;
  border: 2px solid #0971ce;
  display: block;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
/* .add-button input {
  display: none;
} */

.a {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.form-text-list {
  position: absolute;
  margin-top: 720px;
  margin-left: 25px;
}

.input-list-1 {
  position: absolute;
  margin-top: 890px;
  margin-left: 20px;
  height: 48px;
  width: 280px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
}

.input-list-2 {
  position: absolute;
  margin-top: 890px;
  margin-left: 350px;
  height: 48px;
  width: 280px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
}

.input-list-3 {
  position: absolute;
  margin-top: 980px;
  margin-left: 20px;
  width: 280px;
  height: 48px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
}

.input-list-4 {
  position: absolute;
  margin-top: 980px;
  margin-left: 350px;
  width: 280px;
  height: 48px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
}

.aircraft-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 990px;
  left: 700px;
  background: #0971ce;
  color: white;
  border-radius: 40px;
  border: 2px solid #0971ce;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.form-text-mandated {
  position: absolute;
  margin-top: 1100px;
  margin-left: 55px;
}

.form-text-trades {
  position: absolute;
  margin-top: 1230px;
  margin-left: 55px;
}

.form-text-location {
  position: absolute;
  margin-top: 1380px;
  margin-left: 55px;
}

.form-text-duplicate {
  position: absolute;
  margin-top: 1400px;
  margin-left: 55px;
}

.form-text-affected {
  position: absolute;
  margin-top: 1550px;
  margin-left: 55px;
}

.form-text-report {
  position: absolute;
  margin-top: 1600px;
  margin-left: 55px;
}

.edit {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  margin-top: 1800px;
  margin-left: 55px;
  width: 138px;
  height: 48px;
  background: #eef5fc;
  border: 1px solid #0971ce;
  border-radius: 68px;
  font-size: medium;
  color: #0971ce;
  font-weight: 600;
}

.clear {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  margin-top: 1800px;
  margin-left: 200px;
  width: 138px;
  height: 48px;
  background: #eef5fc;
  border: 1px solid #0971ce;
  border-radius: 68px;
  font-size: medium;
  color: #0971ce;
  font-weight: 600;
  cursor: pointer;
}

.next {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0971ce;
  position: absolute;
  margin-top: 1800px;
  margin-left: 850px;
  width: 138px;
  height: 48px;
  color: white;
  gap: 5px;
  border: 1px solid #0971ce;
  border-radius: 68px;
  font-size: medium;
  font-weight: 600;
  cursor: pointer;
}

.radio-button-1 {
  position: absolute;
  margin-left: 55px;
  margin-top: 1150px;
  display: flex;
}

.radio-button-2 {
  position: absolute;
  margin-left: 55px;
  margin-top: 1280px;
  display: flex;
}

.radio-button-3 {
  position: absolute;
  margin-left: 55px;
  margin-top: 1430px;
  display: flex;
}

.radio-button-4 {
  position: absolute;
  margin-left: 55px;
  margin-top: 1450px;
  display: flex;
}

.radio-button-5 {
  position: absolute;
  margin-left: 55px;
  margin-top: 1600px;
  display: flex;
}

.radio-button-6 {
  position: absolute;
  margin-left: 55px;
  margin-top: 1650px;
  display: flex;
}

.spinner {
  margin-left: 48%;
  margin-top: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid rgba(22, 22, 218, 0.2);
  border-top-color: #0971ce;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.custom-modal {
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 30% !important;
  width: 500px;
  height: 100px;
  transform: translate(-50%, -50%);
}

.margin-right {
  margin-right: 5rem;
}

.iteam-caution {
  position: absolute;
  margin-left: 55px;
  margin-top: 70px;
}

.converting {
  position: absolute;
  margin-top: 1850px;
  margin-left: 25%;
  width: 50%;
}

.text-caution {
  position: absolute;
  width: 900px;
  height: 300px;
  background: #ffffff;
  border: 1px solid #0971ce;
  border-radius: 8px;
  top: 80px;
  margin-left: 40px;
  margin-top: 40px;
}

.iteam-comments {
  position: absolute;
  margin-left: 55px;
  margin-top: 500px;
}

.text-comments-field {
  position: absolute;
  width: 900px;
  height: 300px;
  background: #ffffff;
  border: 1px solid #0971ce;
  border-radius: 8px;
  margin-top: 540px;
  margin-left: 40px;
}

.iteam-description {
  position: absolute;
  margin-left: 55px;
  margin-top: 950px;
}

.input-description {
  position: absolute;
  width: 900px;
  height: 280px;
  background: #ffffff;
  border: 1px solid #0971ce;
  border-radius: 8px;
  margin-top: 1000px;
  margin-left: 40px;
}

.iteam-notes {
  position: absolute;
  margin-left: 55px;
  margin-top: 1400px;
}

.file-input {
  margin-left: 45px;
  margin-top: 20px;
  height: 40%;
}

.file-input-box {
  margin-left: 20px;
  margin-top: 20px;
}
.upload-PDF-submit {
  margin-top: 10%;
  margin-left: 35%;
  background-color: #1c79cf;
  border: 1px solid rgba(255, 255, 255, 0);
  color: #ffffff;

  border-radius: 5px;
  width: 80px;
  height: 30px;
  box-shadow: none;
}
.input-notes {
  position: absolute;
  width: 900px;
  height: 300px;
  background: #ffffff;
  border: 1px solid #0971ce;
  border-radius: 8px;
  margin-top: 1450px;
  margin-left: 40px;
}
.file-name {
  height: 10px;
  width: 200px;
  margin-top: -6%;
  margin-left: 23%;
  font-size: small;
}
.custom-modal {
  z-index: 9999 !important ;

  position: fixed !important ;

  top: 50% !important ;

  left: 50% !important ;

  transform: translate(-50%, -50%) !important ;
  background-color: white;
  border: 2px solid rgb(141, 132, 132);
  padding: 30px;
}
.edit-button-second {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  margin-top: 1800px;
  margin-left: 55px;
  width: 138px;
  height: 48px;
  background: #eef5fc;
  border: 1px solid #0971ce;
  border-radius: 68px;
  font-size: medium;
  color: #0971ce;
  font-weight: 600;
}

.clear-button-second {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  margin-top: 1800px;
  margin-left: 200px;
  width: 138px;
  height: 48px;
  background: #eef5fc;
  border: 1px solid #0971ce;
  border-radius: 68px;
  font-size: medium;
  color: #0971ce;
  font-weight: 600;
  cursor: pointer;
}
.button-group {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  justify-content: center;
}
.download-pop-btn {
  background-color: #1c79cf;
  border: 1px solid rgba(255, 255, 255, 0);
  color: #ffffff;
  height: 30px;
  border-radius: 5px;
}
.submit-button {
  position: absolute;
  margin-top: 1800px;
  margin-left: 850px;
  width: 138px;
  height: 48px;
  color: white;
  background: #0971ce;
  border: 1px solid #0971ce;
  border-radius: 68px;
  cursor: pointer;
  font-size: medium;
}

.back-button {
  position: absolute;
  margin-top: 6%;
  margin-left: 5%;
  width: 138px;
  height: 48px;
  color: #0971ce;
  background: #ffffff;
  border: 1px solid #0971ce;
  border-radius: 10px;
  cursor: pointer;
  font-size: medium;
}

.blur-area {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -ms-filter: blur(4px);
  -o-filter: blur(4px);
  filter: blur(4px);
  pointer-events: none;
}
.previous-button {
  position: absolute;
  margin-top: 1800px;
  margin-left: 700px;
  width: 138px;
  height: 48px;
  color: white;
  background: #0971ce;
  border: 1px solid #0971ce;
  border-radius: 68px;
  cursor: pointer;
  font-size: medium;
}

.image-item {
  position: absolute;
  height: 168px !important;
  width: 168px !important;
  margin-top: 150px;
  margin-left: 600px;
}

.thanks-text {
  position: absolute;
  margin-top: 330px;
  margin-left: 500px;
  width: 674px;
  height: 161px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-size: 70px;
  line-height: 88px;
  color: #0971ce;
}

.thanks-text-submitted {
  position: absolute;
  margin-top: 440px;
  margin-left: 460px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-size: 30px;
  color: #0971ce;
}

.file-upload {
  position: absolute;
  margin-left: 970px;
  margin-top: 70px;
}

.file-list {
  position: absolute;

  margin-left: 1020px;
  width: 250px;
  height: 500px;
  margin-top: 100px;
  overflow: hidden;
}

.table-list {
  position: absolute;
  margin-left: 80%;
  margin-top: 100px;
}
th {
  border-bottom: 1px solid black;
  border: 2px solid black;
}
td {
  text-align: center;
  border: 2px solid black;
}
.table-wrapper {
  max-height: 10px !important;
  overflow-y: scroll;
}
.file-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 200px;
  height: 32px;
  padding: 8px;
  background: #0971ce;
  border: 1px solid #0971ce;
  border-radius: 39px;
  color: white;
}
.file-2 {
  gap: 5px;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 150px;
  height: 34px;
  top: 720px;
  left: 750px;
  background: #0971ce;
  color: white;
  border-radius: 40px;
  border: 2px solid #0971ce;
  display: inline;
}
#zip {
  display: none;
}
.cancle-button {
  margin: 10px;
}
.date-test {
  position: absolute;
  width: 280px;
  height: 48px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0971ce !important;
  border-radius: 8px;
}
.margin {
  margin-left: 10rem;
}

.download_link {
  position: absolute;
  margin-top: 1850px;
  margin-left: 400px;
  cursor: pointer;
  font-size: large;
}

.loader-bar {
  height: 10px;
  background-color: #1c79cf; /* Light blue background color */
  margin-top: 10px;
  margin-left: 25%;
  border-radius: 5px;
  animation: loading-animation 5s infinite ease-in-out;
}

@keyframes loading-animation {
  0% {
    width: 0%;
  }
  100% {
    width: 50%;
  }
}

.file-name-sb {
  position: absolute;
  margin-left: 600px;
  margin-top: 45px;
  height: 48px;
  width: 100px !important;
  text-align: center;
  display: flex;
}
.select-PDF-popup {
  position: fixed;
  background-color: white;
  height: 30%;
  width: 20%;
  top: 40vh;
  right: 75vh;
  border: 2px solid rgb(141, 132, 132);
  padding: 30px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  background-color: #cee5fa;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #4652f8;
  word-wrap: break-word;
  max-width: 200px;
}

th {
  background-color: #0971ce;
  color: white;
}

tr:hover {
  background-color: #bfc5f3;
}
/* .scroll {
  width: 200px;
  overflow: scroll;
  height: 200px;
} */

.list-scroll {
  font-size: 14px;
  overflow: scroll;
  height: 460px;
  /* border-bottom: 5px solid #030413; */
  width: 250px;
}
.text-conversion-message {
  margin-left: 50px;
  font-size: medium;
  font-weight: bold;
}
.text-conversion {
  margin-left: 50px;
  margin-top: 20px;
  font-size: medium;
  font-weight: bold;
}
input{
  padding-left:10px
}
.dropdown{
position: absolute;
margin-left: 700px;
bottom: 1945px;
height: 25px;
}
.option-value{
  width: 100px;
  height: 25px;
  position: absolute;
margin-left: 750px;
bottom: 1950px;
}
textarea{
  padding-left:10px;
  padding-top:10px;
}
.ef-table-container {
  width: 750px; /* Set the desired width */
  height: 120px; /* Set the desired height */
  overflow: auto; /* Enable scrolling */
  padding-top: 5px;
  font-size: small;
  border-width: 0cm;
}

.ef-data-table {
  width: 100%; /* Ensure table takes full container width */
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 0%;
  border: none;
}
